import React, { useEffect, useMemo, useState, useRef } from "react";
import { clone, isEmpty, isNil } from "ramda";
import { createColor, getMeasure, shouldAddPoints, shouldAddPointsTotal } from "./utils";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import ReactDOM from "react-dom";
import Legend from "../Gradient";
import "leaflet.offline";
import { IS_ONLINE, MAP_TYPES } from "../../../../../config/configmap";
import { BOOLEAN_MEASURES } from "../../../../utils/consts";
import { useSelector } from "react-redux";
const MapAnalysis = ({
  measureName,
  assetData,
  spatialDataList,
  temporalDataList,
  intl,
  points,
  startIndex,
  endIndex,
  scales,
  percentiles,
  differences,
  equipmentData,
}) => {
  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(true);
  const mapRef = useRef(null);
  const equipmentTypes = useSelector((state) => state.equipmentTypeData.data);
  const [objectMeasures, setObjectMeasures] = useState(null);
  const differencesForTempLength = useState([
    { range: 5000, multiplier: 1 },
    { range: 15000, multiplier: 2 },
    { range: 25000, multiplier: 3 },
  ]);
  useEffect(() => {
    if (!isNil(equipmentTypes)) {
      const newData = equipmentTypes.map((item) => {
        const measures = [];
        const computing = [];
        item.pMeasureNames.forEach((measure, index) => {
          if (item.pMeasureComputed[index] === 0) {
            measures.push(measure);
          } else {
            computing.push(measure);
          }
        });

        const newEntry = { name: item.name, measures: measures };
        if (computing.length > 0) {
          newEntry.computing = computing;
        }
        return newEntry;
      });
      setObjectMeasures(newData);
    }
  }, [equipmentTypes]);

  const { min, max } = useMemo(() => {
    return createColor({ assetData, measureName, percentiles, scales, equipmentData, intl });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tColorScale = scales[measureName];
  useEffect(() => {
    if (!isNil(assetData)) {
      const mapContainer = document.getElementById(`mapAnalysis_${measureName}`);
      if (mapContainer && isNil(map)) {
        const newMap = L.map(mapContainer, { center: assetData.centroid, zoom: assetData.zoom });
        const tileLayer = IS_ONLINE ? "https://tile.openstreetmap.org/{z}/{x}/{y}.png" : MAP_TYPES[0].tileLayer;
        L.tileLayer(tileLayer, {
          maxZoom: 16,
          attribution: "OpenStreetMap",
        }).addTo(newMap);
        setMap(newMap);
      } else {
        console.error(`No se encontró el contenedor del mapa con ID 'mapAnalysis_${measureName}'.`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetData]);

  const polylines = useMemo(() => {
    setLoading(true);
    if (!isNil(assetData) && !isNil(objectMeasures) && !isNil(differences)) {
      let temporalIndexes = [];
      if (!isEmpty(temporalDataList)) {
        temporalIndexes = temporalDataList.map((x) => x.pk);
      }
      const filteredPoints = [];
      let prevValues = {
        state: null,
        values: spatialDataList?.[0]?.values?.[0], //primer valor de la magnitud,
        withColor: false,
      };
      let visualIndexes = clone(points.p_indexes);
      if (!isEmpty(temporalIndexes) && !isNil(startIndex) && !isNil(endIndex)) {
        visualIndexes.push(startIndex);
        visualIndexes.push(endIndex);
        visualIndexes = visualIndexes.filter((item) => item >= startIndex);
        visualIndexes = visualIndexes.filter((item) => item <= endIndex);
      }
      const equipmentType = objectMeasures.find((item) => item.name === equipmentData.equipmentType);
      const umbrales = [];
      if (!equipmentType.computing.includes(measureName)) {
        const measureIndex = equipmentData.pMeasureNames.indexOf(measureName);
        const thisThreshold = equipmentData.pThresholds[measureIndex];
        const thisActive = equipmentData.pThresholdsActive[measureIndex];
        for (let indexActive = 0; indexActive < thisActive.length; indexActive++) {
          if (thisActive[indexActive] === 1) {
            umbrales.push(thisThreshold[indexActive]);
          }
        }
      } else {
        umbrales.push(0.5);
      }
      if (!isEmpty(points) && !isNil(points.points)) {
        let mult = 4;
        for (let indexDiff = 0; indexDiff < differencesForTempLength[0].length; indexDiff++) {
          if (points.points.length < differencesForTempLength[0][indexDiff].range) {
            mult = differencesForTempLength[0][indexDiff].multiplier;
          }
        }
        for (let i = 0; i < points.points.length; i++) {
          let p = clone(points.points[i]);
          if (BOOLEAN_MEASURES.includes(measureName)) {
            p["measuresValue"] = 0;
            for (
              let additionalValuesIndex = 0;
              additionalValuesIndex < spatialDataList?.[0]?.values?.length;
              additionalValuesIndex++
            ) {
              if (
                spatialDataList?.[0]?.values?.[additionalValuesIndex][0] <=
                i <=
                spatialDataList?.[0]?.values?.[additionalValuesIndex][1]
              ) {
                p["measuresValue"] = 1;
              }
            }
          } else {
            p["measuresValue"] = spatialDataList?.[0]?.values?.[i];
          }

          let shouldAdd = false;
          const differenceMultiplier = measureName === "temperature" ? 1 * mult : 6;
          if (!isEmpty(temporalIndexes)) {
            const poss = temporalIndexes.findIndex((t) => t === p.pk);
            if (poss === 0) {
              prevValues.values = p["measuresValue"];
              prevValues.withColor = true;
            }
            shouldAdd = shouldAddPoints({
              indexes: points.p_indexes,
              index: i,
              pk: p.pk,
              thresholds: BOOLEAN_MEASURES.includes(measureName) ? [0.5] : umbrales,
              difference: BOOLEAN_MEASURES.includes(measureName) ? 1 : differences[measureName] * differenceMultiplier,
              value: measureName === "strain" ? Math.abs(p["measuresValue"]) : p["measuresValue"],
              temporalIndexes,
              prevValues,
              filteredPoints,
              visualIndexes,
              startIndex,
              endIndex,
            });
          } else {
            shouldAdd = shouldAddPointsTotal({
              indexes: points.p_indexes,
              index: i,
              pk: p.pk,
              thresholds: BOOLEAN_MEASURES.includes(measureName) ? [0.5] : umbrales,
              difference: BOOLEAN_MEASURES.includes(measureName) ? 1 : differences[measureName] * differenceMultiplier,
              value: p["measuresValue"],
              temporalIndexes,
              prevValues,
              filteredPoints,
              visualIndexes,
            });
          }
          if (shouldAdd) {
            filteredPoints.push({ ...p, index: i, alertType: prevValues.state, withColor: prevValues.withColor });
          }
        }
      }
      const pList = [];
      for (let i = 0; i < filteredPoints.length - 1; i++) {
        const p1 = clone(filteredPoints[i]);
        const p2 = clone(filteredPoints[i + 1]);
        pList.push({ p1, p2, alertType: p1.alertType, withColor: p1.withColor });
      }
      setLoading(false);
      return pList;
    }
    setLoading(false);
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spatialDataList, temporalDataList]);

  const haveTemporalData = useMemo(() => {
    if (!isEmpty(polylines) && !isEmpty(temporalDataList)) {
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polylines]);

  useEffect(() => {
    if (!isNil(map) && !isNil(tColorScale)) {
      map.eachLayer((layer) => {
        if (layer instanceof L.Polyline) {
          map.removeLayer(layer);
        }
      });
      if (haveTemporalData) {
        for (let i = 0; i < polylines.length; i++) {
          //const { min, max } = assetMinMax;
          const weight = haveTemporalData && polylines[i].withColor ? 10 : 5;
          const equipmentType = objectMeasures.find((item) => item.name === equipmentData.equipmentType);
          const umbrales = [];
          if (!equipmentType.computing.includes(measureName)) {
            const measureIndex = equipmentData.pMeasureNames.indexOf(measureName);
            const thisThreshold = equipmentData.pThresholds[measureIndex];
            const thisActive = equipmentData.pThresholdsActive[measureIndex];
            for (let indexActive = 0; indexActive < thisActive.length; indexActive++) {
              if (thisActive[indexActive] === 1) {
                umbrales.push(thisThreshold[indexActive]);
              }
            }
          } else {
            umbrales.push(0.5);
          }
          const color = polylines[i].withColor
            ? tColorScale(
                getMeasure(
                  polylines[i].p1.measuresValue,
                  min,
                  max,
                  BOOLEAN_MEASURES.includes(measureName) ? [0.5] : umbrales,
                  tColorScale.domain(),
                ) ?? 0,
              )
            : "gray";
          var poly = L.polyline(
            [
              [polylines[i].p1.latitude, polylines[i].p1.longitude],
              [polylines[i].p2.latitude, polylines[i].p2.longitude],
            ],
            { color: color, weight: weight },
          );
          if (color !== "gray") {
            poly.on("click", function (e) {
              const googleMapsURL = `https://www.google.com/maps/dir//${polylines[i].p1.latitude},${polylines[i].p1.longitude}`;
              window.open(googleMapsURL, "_blank");
            });
          }
          poly.addTo(map);
        }
      } else {
        for (let i = 0; i < polylines.length; i++) {
          const weight = haveTemporalData && polylines[i].withColor ? 20 : 10;
          const equipmentType = objectMeasures.find((item) => item.name === equipmentData.equipmentType);
          const umbrales = [];
          if (!equipmentType.computing.includes(measureName)) {
            const measureIndex = equipmentData.pMeasureNames.indexOf(measureName);
            const thisThreshold = equipmentData.pThresholds[measureIndex];
            const thisActive = equipmentData.pThresholdsActive[measureIndex];
            for (let indexActive = 0; indexActive < thisActive.length; indexActive++) {
              if (thisActive[indexActive] === 1) {
                umbrales.push(thisThreshold[indexActive]);
              }
            }
          } else {
            umbrales.push(0.5);
          }
          const color = tColorScale(
            getMeasure(
              polylines[i].p1.measuresValue,
              min,
              max,
              BOOLEAN_MEASURES.includes(measureName) ? [0.5] : umbrales,
              tColorScale.domain(),
            ),
          );
          // eslint-disable-next-line no-redeclare
          var poly = L.polyline(
            [
              [polylines[i].p1.latitude, polylines[i].p1.longitude],
              [polylines[i].p2.latitude, polylines[i].p2.longitude],
            ],
            { color: color, weight: weight },
          );

          var popUpContent = `<h4>${intl.formatMessage({ id: measureName })}: ${
            polylines[i].p1.measuresValue
          }</h4><h4>PK: ${polylines[i].p1.pk}</h4>`;
          poly.bindPopup(popUpContent);
          poly.addTo(map);
        }

        var customComponent = document.getElementById(`gradient_${measureName}`);
        ReactDOM.render(
          <Legend
            assetData={assetData}
            equipmentData={equipmentData}
            measureName={measureName}
            percentiles={percentiles}
            scales={scales}
            intl={intl}
          />,
          customComponent,
        );
      }
      map.setView(assetData.centroid, assetData.zoom);
      fitTheBounds();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polylines, map]);

  function fitTheBounds() {
    if (mapRef.current && !isNil(map)) {
      const currentBounds = map.getBounds();
      const southWest = currentBounds.getSouthWest(); // Devuelve un LatLng
      const northEast = currentBounds.getNorthEast(); // Devuelve un LatLng
      map.setMaxBounds([
        [southWest.lat - 0.12, southWest.lng - 0.12],
        [northEast.lat + 0.12, northEast.lng + 0.12],
      ]);
    }
  }

  if (isNil(polylines) || loading) {
    return (
      <div
        style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
      >{`${intl.formatMessage({ id: "loading" })}...`}</div>
    );
  }

  return (
    <>
      <div id={`mapAnalysis_${measureName}`} style={{ width: "47vw", height: "40vh" }} ref={mapRef}>
        <div
          id={`gradient_${measureName}`}
          style={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "10px",
            borderRadius: "5px",
            zIndex: 500,
            width: "13vh",
            height: "21vh",
          }}
          className="leaflet-bottom leaflet-leaflet"
        ></div>
      </div>
    </>
  );
};

export default MapAnalysis;
