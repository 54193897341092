import React, { useEffect, useMemo, useState, useRef } from "react";
import { useIntl } from "react-intl";
import { isEmpty, isNil } from "ramda";

import { spatialSelectHandler } from "./utils";
import LineChart from "../LineChart";
import WaterfallChart from "../WaterfallChart";
import palette from "../../../../../config/colorPalette";
import MapAnalysis from "../MapAnalysis";
import { Button, Checkbox, FormControlLabel, MenuItem, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { getMovingAverageData, getMovingAverageTemporal } from "../../../../api";

const NormalDataVisualization = ({
  title,
  measureName,
  currentAsset,
  assetName,
  asignedChannels,
  initialPointIndex,
  endPointIndex,
  data: spatialDataList,
  temporalData,
  minsW,
  maxsW,
  assetsPoints,
  loadingTemporal,
  datetime,
  currentDateTimeList,
  scales,
  percentiles,
  differences,
  equipment,
  channel,
  selectedEquipment,
  colorMaps,
  isAlert,
  startDate,
  alertMeasure,
  afterAlert,
  setAfterAlert,
}) => {
  const intl = useIntl();
  const [temporalDataList, setTemporalDataList] = useState([]);
  const [environmentalDataList, setEnvironmentalDataList] = useState([]);
  const [minsWaterfall, setMinsWaterfall] = useState([]);
  const [maxsWaterfall, setMaxsWaterfall] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startZoom, setStartZoom] = useState();
  const [endZoom, setEndZoom] = useState();
  const [startIndex, setStartIndex] = useState(null);
  const [endIndex, setEndIndex] = useState(null);
  const [waterfallColors, setWaterfallColors] = useState(null);
  const [period, setPeriod] = useState(30);
  const [maximum, setMaximum] = useState(5000);
  const periodRef = useRef(null);
  const maximumRef = useRef(null);
  const [alertLines, setAlertLines] = useState({ start: null, end: null });
  const equipmentTypes = useSelector((state) => state.equipmentTypeData.data);
  const [movingBoolean, setMovingBoolean] = useState(false); //Promedio móvil
  const [movingAverage, setMovingAverage] = useState(selectedEquipment.channelsSamplingInterval[channel - 1]); //Promedio móvil
  const [chartMovingAverageData, setChartMovingAverageData] = useState(null);
  const [movingBooleanTemporal, setMovingBooleanTemporal] = useState(false); //Promedio móvil
  const [movingAverageTemporal, setMovingAverageTemporal] = useState(selectedEquipment.measureFrequency); //Promedio móvil
  const [averageMeasurement, setAverageMeasurement] = useState("minutes");
  const [chartMovingAverageTemporal, setChartMovingAverageTemporal] = useState(null);
  const [temporalValue, setTemporalValue] = React.useState(
    movingAverageTemporal / (averageMeasurement === "days" ? 86400 : averageMeasurement === "hours" ? 3600 : 60),
  );
  const [loadingAverage, setLoadingAverage] = useState(false);
  const [loadingAverageTemporal, setLoadingAverageTemporal] = useState(false);
  const colors = [
    "#5470C6", // Azul fuerte
    "#91CC75", // Verde suave
    "#EE6666", // Rojo intenso
    "#9A60B4", // Púrpura
    "#FAC858", // Amarillo dorado
    "#EA7CCC", // Rosa claro
    "#FC8452", // Naranja
    "#73C0DE", // Azul cielo
    "#3BA272", // Verde bosque
    "#FF5733", // Naranja rojizo
    "#33FF57", // Verde brillante
    "#5733FF", // Azul eléctrico
    "#FFD700", // Oro
    "#40E0D0", // Turquesa
    "#8A2BE2", // Violeta
    "#FF69B4", // Rosa fuerte
    "#7FFF00", // Chartreuse
    "#DC143C", // Carmesí
    "#00CED1", // Azul oscuro
    "#4682B4", // Acero azul
    "#32CD32", // Verde lima
    "#FF4500", // Naranja rojizo
    "#9400D3", // Violeta oscuro
    "#20B2AA", // Verde aguamarina
    "#FFDAB9", // Melocotón
    "#B22222", // Rojo ladrillo
    "#00FA9A", // Verde primavera
    "#191970", // Azul medianoche
    "#ADFF2F", // Verde amarillo
  ];
  useEffect(() => {
    if (!isAlert) {
      setAfterAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAlert]);

  const points = useMemo(
    () => assetsPoints?.data?.[`${assetName}${asignedChannels[channel - 1] + 1}`] ?? [],
    [assetsPoints, assetName, asignedChannels, channel],
  );

  useEffect(() => {
    if (
      !isNil(spatialDataList) &&
      !isNil(spatialDataList[0].values) &&
      initialPointIndex !== 0 &&
      endPointIndex !== spatialDataList[0].values.length - 1
    ) {
      setStartIndex(initialPointIndex);
      setEndIndex(endPointIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spatialDataList]);

  useEffect(() => {
    const numColors = 15;
    const convertedColormaps = convertColormaps(colorMaps, numColors);
    setWaterfallColors(convertedColormaps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorMaps]);

  function rgbToHex(r, g, b) {
    return "#" + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase();
  }

  function reduceColors(colors, numColors) {
    const step = Math.floor(colors.length / numColors);
    return colors.filter((_, index) => index % step === 0).slice(0, numColors);
  }

  function convertColormaps(colormaps, numColors) {
    const result = {};
    for (const [key, value] of Object.entries(colormaps)) {
      const hexColors = value.red.map((r, i) => rgbToHex(r * 255, value.green[i] * 255, value.blue[i] * 255));
      result[key] = reduceColors(hexColors, numColors);
    }
    return result;
  }

  const chartSpatialData = useMemo(
    () => ({
      xAxis: {
        data: spatialDataList?.[0]?.pk,
      },
      series: spatialDataList?.map((d) => ({
        name: d.datetime,
        data: d.values,
        type: "line",
      })),
      title: `${intl.formatMessage({ id: title })} (${intl.formatMessage({ id: "spatial" })})`,
    }),
    [spatialDataList, title, intl],
  );
  const chartTemporalData = useMemo(() => {
    if (!isEmpty(temporalDataList) && isNil(temporalDataList.image)) {
      const formattedSeries = temporalDataList?.map((d) => ({
        name: d.pk,
        data: d.datetimes.map((datetime, index) => [new Date(datetime), d.values[index]]),
        title: d.measurand,
        type: "line",
        symbolSize: 1,
      }));
      return {
        xAxis: {
          data: temporalDataList?.[0]?.datetimes?.map((str) => str),
        },
        series: formattedSeries,
        title: `${intl.formatMessage({ id: title })} (${intl.formatMessage({ id: "temporal" })})`,
      };
    }
  }, [temporalDataList, title, intl]);

  const chartEnvironmentalData = useMemo(() => {
    if (!isEmpty(environmentalDataList)) {
      const formattedSeries = environmentalDataList?.map((d) => ({
        name: d.pk,
        data: d.datetimes.map((datetime, index) => [new Date(datetime), d.values[index]]),
        title: d.measurand + " " + d.sensor_ID,
        type: "line",
        symbolSize: 1,
      }));
      return {
        xAxis: {
          data: environmentalDataList?.[0]?.datetimes?.map((str) => str),
        },
        series: formattedSeries,
        title: `${intl.formatMessage({ id: "current_intensity" })} (${intl.formatMessage({ id: "temporal" })})`,
      };
    }
  }, [environmentalDataList, intl]);

  const chartTemporalWaterfallData = useMemo(() => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return {
      xAxis: {
        data: temporalDataList?.[0]?.datetimes?.map((str) => new Date(str).toLocaleString("es-ES", options)),
      },
      yAxis: {
        data: temporalDataList?.map((d) => d.pk.toFixed(2)),
      },
      series: [
        {
          data: temporalDataList?.flatMap((d, i) => d.datetimes.map((datetime, j) => [j, i, d.values[j] || "-"])),
        },
      ],
      title: `${intl.formatMessage({ id: title })} (${intl.formatMessage({ id: "waterfall" })})`,
      base64: temporalDataList?.[0]?.base64,
    };
  }, [temporalDataList, title, intl]);

  useEffect(() => {
    const fetchData = async () => {
      if (!movingBoolean) {
        setChartMovingAverageData(null);
        return;
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movingBoolean]);

  useEffect(() => {
    const fetchData = async () => {
      if (!movingBooleanTemporal) {
        setChartMovingAverageTemporal(null);
        return;
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movingBooleanTemporal]);

  useEffect(() => {
    if (!isNil(startIndex) && !isNil(endIndex)) {
      spatialSelectHandler({
        data: chartSpatialData,
        start: startIndex,
        end: endIndex,
        setStartIndex,
        setEndIndex,
        measureName,
        setTemporalDataList,
        setEnvironmentalDataList,
        minsWaterfall,
        setMinsWaterfall,
        maxsWaterfall,
        setMaxsWaterfall,
        currentAsset,
        spatialDataList,
        setLoading,
        datetime,
        equipment,
        selectedEquipment,
        channel,
        period,
        maximum,
        alertMeasure,
        startDate,
        afterAlert,
        setAfterAlert,
        equipmentTypes,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, maximum]);
  useEffect(() => {
    setTemporalDataList(temporalData ?? []);
  }, [temporalData]);
  useEffect(() => {
    setMinsWaterfall(minsW ?? []);
  }, [minsW]);
  useEffect(() => {
    setMaxsWaterfall(maxsW ?? []);
  }, [maxsW]);
  useEffect(() => {
    setLoading(loadingTemporal);
  }, [loadingTemporal]);

  useEffect(() => {
    console.log(temporalDataList);
  }, [temporalDataList]);

  useEffect(
    () =>
      setStartZoom(
        !isNil(initialPointIndex) && !isNil(chartSpatialData?.xAxis?.data)
          ? (initialPointIndex * 100) / chartSpatialData.xAxis.data.length
          : 0,
      ),
    [initialPointIndex, chartSpatialData],
  );
  useEffect(
    () =>
      setEndZoom(
        !isNil(endPointIndex) && !isNil(chartSpatialData?.xAxis?.data)
          ? (endPointIndex * 100) / chartSpatialData.xAxis.data.length
          : 100,
      ),
    [endPointIndex, chartSpatialData],
  );

  if (isNil(spatialDataList)) {
    return <div style={{ height: "92vh", display: "flex", justifyContent: "center", alignItems: "center" }}></div>;
  }

  return (
    !isEmpty(spatialDataList) &&
    !isNil(spatialDataList[0].values) && (
      <div
        style={{
          width: "99%",
          margin: "1rem",
          padding: "0.5rem",
          display: "grid",
          gap: "1rem",
          border: `1px solid ${palette.primary}`,
          pointerEvents: loading ? "none" : "auto",
          position: "relative",
        }}
      >
        <h3 style={{ color: palette.primary }}>{intl.formatMessage({ id: title }).toUpperCase()}</h3>
        {loading && (
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgb(176 176 176 / 60%)",
              zIndex: 2000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              position: "absolute",
            }}
          >
            {`${intl.formatMessage({ id: "loading" })}...`}
          </div>
        )}

        <div style={{ display: "flex", flexDirection: "column", width: "85vw" }}>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
            <FormControlLabel
              control={
                <Checkbox
                  id={"checkbox" + title}
                  sx={{
                    color: palette.primary,
                    "&.Mui-checked": {
                      color: palette.primary,
                    },
                    marginLeft: "15%",
                  }}
                  defaultChecked={movingBoolean}
                  onChange={(e) => {
                    setMovingBoolean(e.target.checked);
                  }}
                />
              }
              label={intl.formatMessage({ id: "moving.average" })}
            />
            {movingBoolean && (
              <div style={{ display: "flex", alignItems: "center", marginBottom: "0.8rem" }}>
                <TextField
                  id={`channel_length_${title}`}
                  style={{ marginRight: "0.5rem", width: "7rem" }}
                  inputProps={{ min: selectedEquipment.channelsSamplingInterval[channel - 1], max: 100 }}
                  defaultValue={movingAverage}
                  //error={errors[`channel_length_${indexChannel}`] ? true : false}
                  variant="outlined"
                  label={intl.formatMessage({ id: "window.size" }) + " (m)"}
                  type="number"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={(e) => setMovingAverage(e.target.value)}
                ></TextField>
                <Button
                  id={`channel_length_${title}_button`}
                  variant="contained"
                  style={{
                    height: "3.6rem",
                    backgroundColor: loadingAverage ? palette.light : palette.primary,
                    color: "white",
                  }}
                  size="small"
                  disabled={loadingAverage}
                  onClick={async () => {
                    setLoadingAverage(true);
                    const measurementValues = [];
                    for (let datalistIndex = 0; datalistIndex < spatialDataList.length; datalistIndex++) {
                      const response = await getMovingAverageData({
                        asset: assetName,
                        equipment: equipment,
                        channel: channel,
                        date: spatialDataList[datalistIndex].datetime,
                        movingAverage: movingAverage,
                      });
                      measurementValues.push(response.data.data[equipment][channel.toString()][measureName]);
                    }
                    const series = [
                      ...spatialDataList?.map((d, dIndex) => ({
                        name: d.datetime,
                        data: d.values,
                        color: colors[dIndex],
                        type: "line",
                        lineStyle: {
                          width: 4.5,
                        },
                      })),
                      ...measurementValues?.map((d, dIndex) => ({
                        name: intl.formatMessage({ id: "moving.average" }) + " " + spatialDataList[dIndex].datetime,
                        data: d,
                        type: "line",
                        symbolSize: 1,
                        color: colors[measurementValues.length + dIndex],
                      })),
                    ];
                    setChartMovingAverageData({
                      xAxis: {
                        data: spatialDataList?.[0]?.pk,
                      },
                      series: series,
                      title: `${intl.formatMessage({ id: title })} (${intl.formatMessage({ id: "moving.average" })})`,
                    });
                  }}
                >
                  {intl.formatMessage({ id: "load.data" })}
                </Button>
              </div>
            )}
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <LineChart
              withToolbox
              id={`${measureName}-spatial`}
              prefix={`${measureName}-spatial`}
              data={!isNil(chartMovingAverageData) ? chartMovingAverageData : chartSpatialData}
              measureName={measureName}
              isAlert={isAlert}
              resetZoomHandler={() => {
                setStartZoom(0);
                setEndZoom(100);
                setTemporalDataList([]);
              }}
              pointsSelectHandler={(start, end, auxAfter) => {
                const periodValue = isNil(periodRef.current) ? period : periodRef.current.value;
                const maximumValue = isNil(maximumRef.current) ? maximum : maximumRef.current.value;
                spatialSelectHandler({
                  data: !isNil(chartMovingAverageData) ? chartMovingAverageData : chartSpatialData,
                  start,
                  end,
                  setStartIndex,
                  setEndIndex,
                  measureName,
                  setTemporalDataList,
                  setEnvironmentalDataList,
                  minsWaterfall,
                  setMinsWaterfall,
                  maxsWaterfall,
                  setMaxsWaterfall,
                  currentAsset,
                  spatialDataList,
                  setLoading,
                  datetime,
                  selectedEquipment,
                  equipment,
                  channel,
                  period: periodValue,
                  maximum: maximumValue,
                  alertMeasure,
                  startDate,
                  afterAlert: auxAfter,
                  setAfterAlert,
                  equipmentTypes,
                });
              }}
              startIndex={startIndex}
              endIndex={endIndex}
              startZoom={startZoom ?? 0}
              endZoom={endZoom ?? 100}
              alertMeasure={alertMeasure}
              afterAlert={afterAlert}
              setAfterAlert={setAfterAlert}
              alertLines={alertLines}
              setAlertLines={setAlertLines}
              intl={intl}
              setLoadingAverage={setLoadingAverage}
              setLoadingAverageTemporal={setLoadingAverageTemporal}
            />
            <MapAnalysis
              assetData={currentAsset}
              intl={intl}
              measureName={measureName}
              spatialDataList={spatialDataList}
              temporalDataList={temporalDataList}
              points={points}
              startIndex={startIndex}
              endIndex={endIndex}
              scales={scales}
              percentiles={percentiles}
              differences={differences}
              equipmentData={selectedEquipment}
            />
          </div>
        </div>
        {(!isEmpty(temporalDataList) || (!isEmpty(environmentalDataList) && isEmpty(temporalDataList))) &&
          !isNil(chartTemporalData) &&
          !loading && (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 0, margin: 0 }}>
              <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                <TextField
                  select
                  style={{ width: 150 }}
                  id={`${measureName}_period_chart`}
                  variant="outlined"
                  label={intl.formatMessage({ id: "period" })}
                  margin="dense"
                  value={period}
                  onChange={(e) => {
                    setPeriod(e.target.value);
                  }}
                  inputRef={periodRef}
                >
                  <MenuItem key={7} value={7}>
                    7 {intl.formatMessage({ id: "days" })}
                  </MenuItem>
                  <MenuItem key={30} value={30}>
                    30 {intl.formatMessage({ id: "days" })}
                  </MenuItem>
                  <MenuItem key={60} value={60}>
                    60 {intl.formatMessage({ id: "days" })}
                  </MenuItem>
                  <MenuItem key={90} value={90}>
                    90 {intl.formatMessage({ id: "days" })}
                  </MenuItem>
                  <MenuItem key={365} value={365}>
                    1 {intl.formatMessage({ id: "year" })}
                  </MenuItem>
                  <MenuItem key={Infinity} value={Infinity}>
                    {intl.formatMessage({ id: "all.time" })}
                  </MenuItem>
                </TextField>
                <TextField
                  select
                  style={{ marginLeft: "1.10%", marginRight: "1.10%", width: 150 }}
                  id={`${measureName}_max_chart`}
                  variant="outlined"
                  label={intl.formatMessage({ id: "max.registers" })}
                  margin="dense"
                  value={maximum}
                  onChange={(e) => {
                    setMaximum(e.target.value);
                  }}
                  inputRef={maximumRef}
                >
                  <MenuItem key={1000} value={1000}>
                    1000
                  </MenuItem>
                  <MenuItem key={5000} value={5000}>
                    5000
                  </MenuItem>
                  <MenuItem key={10000} value={10000}>
                    10000
                  </MenuItem>
                  <MenuItem key={25000} value={25000}>
                    25000
                  </MenuItem>
                  <MenuItem key={Infinity} value={Infinity}>
                    {intl.formatMessage({ id: "no.limits" })}
                  </MenuItem>
                </TextField>
                {startIndex === endIndex && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={"checkbox" + title + "temporal"}
                        sx={{
                          color: palette.primary,
                          "&.Mui-checked": {
                            color: palette.primary,
                          },
                          marginLeft: "35%",
                        }}
                        defaultChecked={movingBooleanTemporal}
                        onChange={(e) => {
                          setMovingBooleanTemporal(e.target.checked);
                        }}
                      />
                    }
                    label={intl.formatMessage({ id: "moving.average" })}
                  />
                )}
                {movingBooleanTemporal && (
                  <div style={{ display: "flex", alignItems: "center", marginBottom: "0.8rem" }}>
                    <TextField
                      id={`moving_average_${title}_temporal`}
                      style={{ margin: "1.10%" }}
                      inputProps={{
                        min:
                          selectedEquipment.measureFrequency /
                          (averageMeasurement === "days" ? 86400 : averageMeasurement === "hours" ? 3600 : 60),
                        max:
                          7776000 /
                          (averageMeasurement === "days" ? 86400 : averageMeasurement === "hours" ? 3600 : 60),
                      }}
                      value={temporalValue}
                      //error={errors[`channel_length_${indexChannel}`] ? true : false}
                      variant="outlined"
                      label={intl.formatMessage({ id: "window.size" })}
                      type="number"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setTemporalValue(parseFloat(e.target.value).toFixed(2));
                      }}
                      onBlur={(e) => {
                        const conversor =
                          averageMeasurement === "days" ? 86400 : averageMeasurement === "hours" ? 3600 : 60;

                        setMovingAverageTemporal(e.target.value * conversor);
                      }}
                    ></TextField>
                    <TextField
                      select
                      style={{ width: 150, margin: "1.10%" }}
                      id={`${title}_average_measurement`}
                      variant="outlined"
                      margin="dense"
                      value={averageMeasurement}
                      onChange={(e) => {
                        setTemporalValue(
                          movingAverageTemporal /
                            (e.target.value === "days" ? 86400 : e.target.value === "hours" ? 3600 : 60),
                        );
                        setAverageMeasurement(e.target.value);
                      }}
                      //inputRef={maximumRef}
                    >
                      <MenuItem key={"days"} value={"days"}>
                        {intl.formatMessage({ id: "days" })}
                      </MenuItem>
                      <MenuItem key={"hours"} value={"hours"}>
                        {intl.formatMessage({ id: "hours" })}
                      </MenuItem>
                      <MenuItem key={"minutes"} value={"minutes"}>
                        {intl.formatMessage({ id: "minutes" })}
                      </MenuItem>
                    </TextField>
                    <Button
                      variant="contained"
                      style={{
                        margin: "1.10%",
                        height: "3.6rem",
                        backgroundColor: loadingAverageTemporal ? palette.light : palette.primary,
                        color: "white",
                      }}
                      size="small"
                      disabled={loadingAverageTemporal}
                      onClick={async () => {
                        setLoadingAverageTemporal(true);
                        const response = await getMovingAverageTemporal({
                          asset: assetName,
                          equipment: equipment,
                          channel: channel,
                          date: datetime,
                          movingAverage: parseInt(movingAverageTemporal),
                          startIndex,
                          endIndex,
                          maximum,
                          endDate: datetime,
                          user_period: period,
                        });
                        const measurementValues = response.data.data.data[0][measureName];
                        if (!isEmpty(temporalDataList)) {
                          const formattedSeries = [
                            ...temporalDataList?.map((d, dIndex) => ({
                              name: d.pk,
                              data: d.datetimes.map((datetime, index) => [new Date(datetime), d.values[index]]),
                              title: d.measurand,
                              color: colors[dIndex],
                              type: "line",
                              lineStyle: {
                                width: 4.5,
                              },
                            })),
                            {
                              name: "Average",
                              data: measurementValues.map((valueData) => [
                                new Date(valueData.datetime),
                                valueData.value,
                              ]),
                              title: "Average temperature",
                              color: colors[temporalDataList.length],
                              type: "line",
                              symbolSize: 1,
                            },
                          ];
                          setChartMovingAverageTemporal({
                            xAxis: {
                              data: temporalDataList?.[0]?.datetimes?.map((str) => str),
                            },
                            series: formattedSeries,
                            title: `${intl.formatMessage({ id: title })} (${intl.formatMessage({ id: "temporal" })})`,
                          });
                        }
                      }}
                    >
                      {intl.formatMessage({ id: "load.data" })}
                    </Button>
                  </div>
                )}
              </div>
              <div style={{ width: "100%" }}>
                {startIndex === endIndex ? (
                  <LineChart
                    withToolbox
                    prefix={`${measureName}-temporal`}
                    data={!isNil(chartMovingAverageTemporal) ? chartMovingAverageTemporal : chartTemporalData}
                    environmentalData={chartEnvironmentalData}
                    isAlert={isAlert}
                    alertMeasure={alertMeasure}
                    measureName={measureName}
                    afterAlert={afterAlert}
                    setAfterAlert={setAfterAlert}
                    alertLines={alertLines}
                    setAlertLines={setAlertLines}
                    setLoadingAverage={setLoadingAverage}
                    setLoadingAverageTemporal={setLoadingAverageTemporal}
                    intl={intl}
                  />
                ) : (
                  <WaterfallChart
                    prefix={`${measureName}-waterfall`}
                    data={chartTemporalWaterfallData}
                    boundaries={
                      measureName === "strain"
                        ? [-maxsWaterfall?.[measureName], maxsWaterfall?.[measureName]]
                        : [minsWaterfall?.[measureName], maxsWaterfall?.[measureName]]
                    }
                    colorRange={measureName === "strain" ? waterfallColors.seismic : waterfallColors.jet}
                  />
                )}
              </div>
            </div>
          )}
        {loading && (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
              <TextField
                select
                style={{ width: 150 }}
                id="period_chart"
                variant="outlined"
                label={intl.formatMessage({ id: "period" })}
                margin="dense"
                value={period}
                onChange={(e) => {
                  setPeriod(e.target.value);
                }}
              >
                <MenuItem key={7} value={7}>
                  7 {intl.formatMessage({ id: "days" })}
                </MenuItem>
                <MenuItem key={30} value={30}>
                  30 {intl.formatMessage({ id: "days" })}
                </MenuItem>
                <MenuItem key={60} value={60}>
                  60 {intl.formatMessage({ id: "days" })}
                </MenuItem>
                <MenuItem key={90} value={90}>
                  90 {intl.formatMessage({ id: "days" })}
                </MenuItem>
                <MenuItem key={365} value={365}>
                  1 {intl.formatMessage({ id: "year" })}
                </MenuItem>
                <MenuItem key={"period" + Infinity} value={Infinity}>
                  {intl.formatMessage({ id: "all.time" })}
                </MenuItem>
              </TextField>
              <TextField
                select
                style={{ width: 150 }}
                id="max_chart"
                variant="outlined"
                label={intl.formatMessage({ id: "max.registers" })}
                margin="dense"
                value={maximum}
                onChange={(e) => {
                  setMaximum(e.target.value);
                }}
              >
                <MenuItem key={1000} value={1000}>
                  1000
                </MenuItem>
                <MenuItem key={5000} value={5000}>
                  5000
                </MenuItem>
                <MenuItem key={10000} value={10000}>
                  10000
                </MenuItem>
                <MenuItem key={25000} value={25000}>
                  25000
                </MenuItem>
                <MenuItem key={"register" + Infinity} value={Infinity}>
                  {intl.formatMessage({ id: "no.limits" })}
                </MenuItem>
              </TextField>
            </div>
            <div style={{ width: "100%" }}>
              {startIndex === endIndex ? (
                <LineChart
                  prefix={`${measureName}-temporal-empty`}
                  data={[]}
                  environmentalData={[]}
                  setLoadingAverageTemporal={setLoadingAverageTemporal}
                  setLoadingAverage={setLoadingAverage}
                  intl={intl}
                />
              ) : (
                <WaterfallChart
                  prefix={`${measureName}-waterfall-empty`}
                  data={[]}
                  boundaries={[0, 1]}
                  colorRange={
                    measureName === "strain"
                      ? ["#004700", "#006600", "#00b300", "#ffffbf", "#fdae61", "#f46d43", "#a50026"]
                      : [
                          "#313695",
                          "#4575b4",
                          "#74add1",
                          "#abd9e9",
                          "#e0f3f8",
                          "#ffffbf",
                          "#fee090",
                          "#fdae61",
                          "#f46d43",
                          "#d73027",
                          "#a50026",
                        ]
                  }
                />
              )}
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default NormalDataVisualization;
