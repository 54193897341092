import React from "react";
import { isNil } from "ramda";

const WaterfallChart = ({ prefix, data, boundaries, colorRange }) => {
  if (isNil(data.base64)) {
    return null;
  }
  return (
    <div style={{ overflow: "hidden" }}>
      <img
        src={`data:image/png;base64,${data.base64}`}
        alt={prefix + "image"}
        style={{ width: "100%", height: "auto", maxWidth: "100vw" }}
      />
    </div>
  );
};

export default WaterfallChart;
