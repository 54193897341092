import { isEmpty, isNil } from "ramda";
import { toast } from "react-toastify";

import { getAnalysisSpatialData, getAssetPointsAdditionalData, getAssetPointsSerialData } from "../../../../api";

export const loadSpatialData = async ({ asset, dateList, measureName, data, assetData, setData, setLoading }) => {
  setLoading(true);
  try {
    const result = [];
    for (let i = 0; i < dateList.length; i++) {
      const date = dateList[i];
      const isDuplicatedDate = data.filter((d) => d.datetime === date).lenght > 0;
      if (!isDuplicatedDate) {
        const spatialDataResponse = await getAnalysisSpatialData(asset, date);
        if (
          !isNil(spatialDataResponse?.status) &&
          spatialDataResponse.status === 200 &&
          !isNil(spatialDataResponse.data)
        ) {
          const spatialData = { ...spatialDataResponse.data.data?.[0] };
          const newItem = {
            asset: spatialData.asset,
            datetime: spatialData.info.datetime,
            values: spatialData[measureName],
            //pk: assetData?.points.map((p) => p.pk),
            pk: spatialData[measureName].map((d, i) => i),
          };
          result.push(newItem);
        }
      }
    }
    await setData(result);
  } catch (error) {
    toast.error(error.message, { className: "toast-error" });
  }
  setLoading(false);
};

export const loadTemporalData = async ({
  asset,
  startIndex,
  endIndex,
  pkList,
  measureName,
  setTemporalDataList,
  setMinsWaterfall,
  setMaxsWaterfall,
  minsWaterfall,
  maxsWaterfall,
  setLoading,
  datetime,
  equipment,
  channel,
  period,
  maximum,
  alertMeasure,
  startDate,
  afterAlert,
  setAfterAlert,
  selectedEquipment,
  equipmentTypes,
}) => {
  try {
    //const sensorIDsMeasurements = asset.sensors.filter((sensor) => {
    //  const channelSensorID = selectedEquipment.channelsSensorsIDs[channel - 1];
    //  const includes = sensor.measurands.some((measurand) => measurand.includes(measureName));
    //
    //  return channelSensorID.includes(sensor.sensor_ID) && includes;
    //});
    const allSensorIDs = [];
    const allSensorIDsMeasurands = [];
    //if (!thisEquipment[0].pMeasureNames.includes(measureName)) {
    //  for (let index = 0; index < sensorIDsMeasurements.length; index++) {
    //    if (selectedEquipment.channelsSensorsIDs[channel - 1].includes(sensorIDsMeasurements[index].sensor_ID)) {
    //      const aux = selectedEquipment.channelsSensorsIDs[channel - 1].indexOf(sensorIDsMeasurements[index].sensor_ID);
    //      allSensorIDs.push(selectedEquipment.channelsSensorsIDs[channel - 1][aux]);
    //      allSensorIDsMeasurands.push(sensorIDsMeasurements[index].measurands);
    //    }
    //  }
    //}
    let response = null;
    if (measureName !== "current_intensity" && isEmpty(allSensorIDs)) {
      response = await getAssetPointsSerialData(
        asset.name,
        startIndex,
        endIndex,
        datetime,
        [measureName],
        equipment,
        channel,
        period,
        maximum,
        startDate,
        afterAlert,
      );
    } else {
      response = [];
      const channelSensorID = selectedEquipment.channelsSensorsIDs[channel - 1];
      for (let indexSensor = 0; indexSensor < asset.sensors.length; indexSensor++) {
        if (channelSensorID.includes(asset.sensors[indexSensor].sensor_ID)) {
          for (
            let indexMeasurand = 0;
            indexMeasurand < asset.sensors[indexSensor].measurands.length;
            indexMeasurand++
          ) {
            allSensorIDs.push(asset.sensors[indexSensor].sensor_ID);
            allSensorIDsMeasurands.push([asset.sensors[indexSensor].measurands[indexMeasurand]]);
            const addition = await getAssetPointsAdditionalData(
              asset.name,
              datetime,
              asset.sensors[indexSensor].measurands[indexMeasurand], //medida
              equipment,
              period,
              maximum,
              channel,
              startDate,
              afterAlert,
              asset.sensors[indexSensor].sensor_ID, //ID
              startIndex,
              endIndex,
            );
            response.push(addition);
          }
        }
      }
      //for (let index = 0; index < allSensorIDs.length; index++) {
      //  const addition = await getAssetPointsAdditionalData(
      //    asset.name,
      //    datetime,
      //    allSensorIDsMeasurands[index],
      //    equipment,
      //    period,
      //    maximum,
      //    channel,
      //    startDate,
      //    afterAlert,
      //    allSensorIDs[index],
      //    startIndex,
      //    endIndex,
      //  );
      //  response.push(addition);
      //}
    }
    const result = [];
    let minValue = Infinity;
    let maxValue = Number.NEGATIVE_INFINITY;
    if (measureName !== "current_intensity") {
      if (!isNil(response?.status) && response.status === 200 && !isNil(response.data)) {
        const data = response.data?.data;
        if (!isNil(data.data)) {
          for (let i = 0, j = startIndex; i < data.data.length; i++, j++) {
            const item = data.data[i];
            const values = item[measureName].map((item) => item.value);
            const min = Math.min(...values);
            const max = Math.max(...values);
            if (minValue > min) minValue = min;
            if (maxValue < max) maxValue = max;
            if (!isNil(data.images) && !isEmpty(data.images) && i === 0) {
              result.push({
                values: item[measureName].map((v) => v.value),
                datetimes: item[measureName].map((v) => v.datetime),
                pk: pkList[i],
                measurand: measureName,
                base64: data.images[measureName],
              });
            } else {
              result.push({
                values: item[measureName].map((v) => v.value),
                datetimes: item[measureName].map((v) => v.datetime),
                pk: pkList[i],
                measurand: measureName,
              });
            }
          }
        }
      }
      if (Math.abs(minValue) > maxValue && measureName === "strain") {
        maxValue = Math.abs(minValue);
      }
      setMinsWaterfall({ ...minsWaterfall, [measureName]: minValue });
      setMaxsWaterfall({ ...maxsWaterfall, [measureName]: maxValue });
    } else {
      for (let index = 0; index < response.length; index++) {
        if (!isNil(response[index]?.status) && response[index].status === 200 && !isNil(response[index].data)) {
          const data = response[index].data?.data;
          if (!isNil(data.data)) {
            for (let i = 0, j = startIndex; i < data.data.length; i++, j++) {
              for (let indexMeasurand = 0; indexMeasurand < allSensorIDsMeasurands[index].length; indexMeasurand++) {
                const item = data.data[i];
                if (!isNil(item[allSensorIDsMeasurands[index][indexMeasurand]])) {
                  const values = item[allSensorIDsMeasurands[index][indexMeasurand]].map((item) => item.value);
                  const min = Math.min(...values);
                  const max = Math.max(...values);
                  if (minValue > min) minValue = min;
                  if (maxValue < max) maxValue = max;
                  result.push({
                    sensor_ID: allSensorIDs[index],
                    values: item[allSensorIDsMeasurands[index][indexMeasurand]].map((v) => v.value),
                    datetimes: item[allSensorIDsMeasurands[index][indexMeasurand]].map((v) => v.datetime),
                    pk: pkList[i],
                    measurand: allSensorIDsMeasurands[index][indexMeasurand],
                  });
                  if (Math.abs(minValue) > maxValue && allSensorIDsMeasurands[index][indexMeasurand] === "strain") {
                    maxValue = Math.abs(minValue);
                    setMinsWaterfall({ ...minsWaterfall, [allSensorIDsMeasurands[index][indexMeasurand]]: minValue });
                    setMaxsWaterfall({ ...maxsWaterfall, [allSensorIDsMeasurands[index][indexMeasurand]]: maxValue });
                  }
                }
              }
            }
          }
        }
      }
    }

    setTemporalDataList(result);
  } catch (error) {
    console.log(error);
    toast.error(error.message, { className: "toast-error" });
  } finally {
    if (measureName === "current_intensity" || startIndex !== endIndex) {
      setLoading(false);
    }
    if (!afterAlert) {
      setAfterAlert(true);
    }
  }
};

export const spatialSelectHandler = ({
  data,
  start,
  end,
  setStartIndex,
  setEndIndex,
  measureName,
  setTemporalDataList,
  setEnvironmentalDataList,
  minsWaterfall,
  setMinsWaterfall,
  maxsWaterfall,
  setMaxsWaterfall,
  currentAsset,
  spatialDataList,
  setLoading,
  datetime,
  equipment,
  selectedEquipment,
  channel,
  period,
  maximum,
  alertMeasure,
  startDate,
  afterAlert,
  setAfterAlert,
  equipmentTypes,
}) => {
  setLoading(true);
  if (!afterAlert) {
    if (!(measureName === "strain" && alertMeasure === "leak")) {
      let i = start;
      let j = end;
      setStartIndex(i);
      setEndIndex(j);
      if (j - i >= 64) {
        let centerPoint = parseInt((j - i) / 2) + i;
        i = centerPoint >= 32 ? centerPoint - 32 : 0;
        centerPoint += 32;
        const count = data?.series?.[0]?.data?.length ?? 0;
        j = centerPoint < count ? centerPoint : count - 1;
      }
      if (i !== j) {
        loadTemporalData({
          asset: currentAsset,
          startIndex: i,
          endIndex: j,
          pkList: spatialDataList?.[0]?.pk?.slice(i, j + 1),
          measureName,
          setTemporalDataList,
          setMinsWaterfall,
          setMaxsWaterfall,
          minsWaterfall,
          maxsWaterfall,
          setLoading,
          datetime,
          equipment,
          channel,
          period,
          maximum,
          startDate,
          alertMeasure,
          afterAlert,
          setAfterAlert,
          selectedEquipment,
          equipmentTypes,
        });
      }

      if (i === j) {
        loadTemporalData({
          asset: currentAsset,
          pkList: spatialDataList?.[0]?.pk?.slice(i, j + 1),
          measureName: "current_intensity",
          setTemporalDataList: setEnvironmentalDataList,
          setMinsWaterfall,
          setMaxsWaterfall,
          minsWaterfall,
          maxsWaterfall,
          setLoading,
          datetime,
          equipment,
          period,
          maximum,
          channel,
          startIndex: null,
          startDate,
          alertMeasure,
          afterAlert,
          setAfterAlert,
          selectedEquipment,
          equipmentTypes,
        });
      }
    } else {
      setLoading(false);
      alertMeasure = null;
    }
  } else {
    let i = start;
    let j = end;
    setStartIndex(i);
    setEndIndex(j);
    if (j - i >= 64) {
      let centerPoint = parseInt((j - i) / 2) + i;
      i = centerPoint >= 32 ? centerPoint - 32 : 0;
      centerPoint += 32;
      const count = data?.series?.[0]?.data?.length ?? 0;
      j = centerPoint < count ? centerPoint : count - 1;
    }
    loadTemporalData({
      asset: currentAsset,
      startIndex: i,
      endIndex: j,
      pkList: spatialDataList?.[0]?.pk?.slice(i, j + 1),
      measureName,
      setTemporalDataList,
      setMinsWaterfall,
      setMaxsWaterfall,
      minsWaterfall,
      maxsWaterfall,
      setLoading,
      datetime,
      equipment,
      channel,
      period,
      maximum,
      startDate,
      alertMeasure,
      afterAlert,
      setAfterAlert,
      selectedEquipment,
      equipmentTypes,
    });

    if (i === j) {
      loadTemporalData({
        asset: currentAsset,
        pkList: spatialDataList?.[0]?.pk?.slice(i, j + 1),
        measureName: "current_intensity",
        setTemporalDataList: setEnvironmentalDataList,
        setMinsWaterfall,
        setMaxsWaterfall,
        minsWaterfall,
        maxsWaterfall,
        setLoading,
        datetime,
        equipment,
        period,
        maximum,
        channel,
        startIndex: null,
        startDate,
        alertMeasure,
        afterAlert,
        setAfterAlert,
        selectedEquipment,
        equipmentTypes,
      });
    }
  }
};
