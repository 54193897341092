
import { URL } from "../../config/configmap";

const TYPE_WS = 'wss' //https => wss || http => ws
const BASE_WS_URL =`${TYPE_WS}://${URL}/ws`
const topics = ['alerts'];

export const getWSConnections = (token,loadData) => {
  let wsConnections = {};

  topics.forEach((topic) => {
    const ws = createWebSocket(token, topic);
    ws.onopen = (event) => onOpenWS(event, topic);
    ws.onmessage = (event) => onMessageWS(event, topic,loadData);
    ws.onclose = (event) => onCloseWS(event, topic);

    wsConnections[topic] = ws;
  });

  return wsConnections
}

const createWebSocket = (token, topic) => {
  const ws_url_subscribe = `${BASE_WS_URL}/${token}/subscribe`;
  const ws = new WebSocket(`${ws_url_subscribe}/${topic}`);
  return ws;
};

const onOpenWS = (event, topic) => {
  console.log(`WebSocket connected to topic: ${topic}`);
};

const onMessageWS = (event, topic,loadData) => {
  console.log(JSON.parse(event.data));
  loadData(event,topic)
};

const onCloseWS = (event, topic) => {
  console.log(`WebSocket closed for topic: ${topic}`);
};