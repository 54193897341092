import React, { useMemo, useState } from "react";
import { isNil } from "ramda";
import { useIntl } from "react-intl";

import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import Checkbox from "@mui/material/Checkbox";
import LoadingButton from "@mui/lab/LoadingButton";

import { ErrorText, LinkBox, LoginContent, RememberContent, TextBox } from "../styled";
import { handleChanges } from "../utils/utils";
import palette from "../../../../config/colorPalette";
import { validateEmail, validatePassword } from "../../../utils/utils";
import ForgetPasswordModal from "./ForgetPasswordModal";

const LoginForm = ({ handleLogin, loading = false, testId }) => {
  const intl = useIntl();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState(false);
  const [usernameError, setUsernameError] = useState();
  const [passwordError, setPasswordError] = useState();

  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);

  const disabled = useMemo(
    () => loading || !validateEmail(username) || !validatePassword(password),
    [loading, username, password],
  );

  return (
    <>
      <LoginContent>
        <TextBox size="2rem">{intl.formatMessage({ id: "welcome" })}</TextBox>
        <TextBox size="1rem">{intl.formatMessage({ id: "login.username.text" })}</TextBox>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="email">{intl.formatMessage({ id: "username" })}</InputLabel>
          <OutlinedInput
            id="email"
            type="email"
            required
            endAdornment={
              <InputAdornment position="end">
                <AlternateEmailIcon color="#e4e6ef" />
              </InputAdornment>
            }
            label={intl.formatMessage({ id: "username" })}
            value={username ?? ""}
            onChange={(event) =>
              handleChanges({
                who: "username",
                value: event.target.value,
                setUsernameError,
                setUsername,
                setPasswordError,
                setPassword,
                intl,
              })
            }
            error={usernameError ? true : false}
            data-testid={`${testId}-email`}
          />
          {!isNil(usernameError) && <ErrorText>{intl.formatMessage({ id: usernameError })}</ErrorText>}
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="password">{intl.formatMessage({ id: "password" })}</InputLabel>
          <OutlinedInput
            id="password"
            type={showPassword ? "text" : "password"}
            required
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(event) => event.preventDefault()}
                  edge="end"
                  data-testid={`${testId}-visibility`}
                >
                  {showPassword ? <VisibilityOff color="#e4e6ef" /> : <Visibility color="#e4e6ef" />}
                </IconButton>
              </InputAdornment>
            }
            label={intl.formatMessage({ id: "password" })}
            value={password ?? ""}
            onChange={(event) =>
              handleChanges({
                who: "password",
                value: event.target.value,
                setUsernameError,
                setUsername,
                setPasswordError,
                setPassword,
                intl,
              })
            }
            error={passwordError ? true : false}
            data-testid={`${testId}-password`}
          />
          {!isNil(passwordError) && <ErrorText>{intl.formatMessage({ id: passwordError })}</ErrorText>}
        </FormControl>
        <RememberContent>
          <LinkBox color={palette.link}>
            <Checkbox checked={remember} onClick={() => setRemember(!remember)} sx={{ color: palette.link }} />
            {intl.formatMessage({ id: "remember.me" })}
          </LinkBox>
          <LinkBox
            id="forgot-password"
            onClick={() => {
              setForgotPasswordModal(true);
            }}
            color={palette.link}
          >
            {intl.formatMessage({ id: "forgot.password" })}
          </LinkBox>
        </RememberContent>
        <LoadingButton
          id="login-button"
          size="large"
          onClick={() => handleLogin(username, password, remember)}
          loading={loading ?? false}
          variant="contained"
          fullWidth
          disabled={disabled}
          style={{ backgroundColor: disabled ? palette.light : palette.primary, fontFamily: "fagoco" }}
        >
          <span>{intl.formatMessage({ id: "login" })}</span>
        </LoadingButton>
        <TextBox size="0.75rem">
          {`${intl.formatMessage({ id: "trouble.message" })} `}
          <a
            style={{ color: palette.link, cursor: "pointer", textDecoration: "none" }}
            href="mailto:sysadmin@uptech-sensing.com"
          >
            sysadmin@uptech-sensing.com
          </a>
        </TextBox>
      </LoginContent>
      {forgotPasswordModal && <ForgetPasswordModal open={forgotPasswordModal} setOpen={setForgotPasswordModal} />}
    </>
  );
};
export default LoginForm;
