import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { isEmpty, isNil } from "ramda";
 
import { Box, Tab, Tabs, CircularProgress } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
 
import { menuAccessVerification } from "../../utils/utils";
import { loadAlerts } from "./utils/utils";
import { setSelectedMenuOptionAction } from "../../redux/menuSlice";
import { getPointsByAssets } from "../../api";
import AlarmTable from "./components/AlarmTable";
import { getWSConnections } from "../../ws";
import { getFromCookie } from "../../api/auth";
 
const Alarms = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const { assetName } = useParams();
  const [value, setValue] = useState("1"); // Ensure value is a string
  const accessToken = getFromCookie("uptechAccessToken");
  const [socketAlerts, setSocketAlerts] = useState([]);
 
  const assetsData = useSelector((state) => state.assetsData);
  const userData = useSelector((state) => state.userData.data);
  const menuData = useSelector((state) => state.menuData.data);
  const localeData = useSelector((state) => state.configData.locale);
  const pointsData = useSelector((state) => state.assetsPoints.data);
 
  const [alerts, setAlerts] = useState(null);
  const [assetsPoints, setAssetsPoints] = useState();
  const [loading, setLoading] = useState(true);
 
  //WS
  const loadData = (event, topic) => {
    console.log("LOAD DATA AFTER  ON MESSAGE");
    const result = JSON.parse(event.data);
    if (topic === "alerts") {
      if (result.data.info.model.length > 0) {
        //toast.info(intl.formatMessage({ id: "alert.list.modified" }), { className: "toast-info" });
        setSocketAlerts(result.data.info.model);
      } else {
        setSocketAlerts([]);
      }
    }
  };
 
  useEffect(() => {
    const wsConnections = getWSConnections(accessToken,loadData);
    return () => {
      // Cleanup all WebSocket connections on unmount
      Object.values(wsConnections).forEach((ws) => {
        if (ws.readyState === WebSocket.OPEN || ws.readyState === WebSocket.CONNECTING) {
          ws.close();
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);
 
  useEffect(() => {
    if (!isNil(alerts)) {
      if (isEmpty(socketAlerts)) {
        setSocketAlerts([]);
      } else {
        const updatedAlerts = [...alerts];
        socketAlerts.forEach(function (socketAlert, keySocketAlert) {
          let index = -1;
          let exist_alert = updatedAlerts.filter(function (alert, key) {
            if (alert.id === socketAlert.id) {
              index = key;
              return true;
            }
          });
          console.log(index, exist_alert);
 
          if (!isNil(exist_alert) && index !== -1) {
            let item = exist_alert[0]
            
            for (let key in socketAlert) {
              item[key] = socketAlert[key];
            }
            updatedAlerts[index] = item
          } else {
            updatedAlerts.push(socketAlert);
          }
        });
        setAlerts(updatedAlerts);
      }
    }
  }, [socketAlerts]);
  //WS
 
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 
  useEffect(() => {
    async function fetchAssetsPoints() {
      if (isNil(assetsData?.data) || isEmpty(alerts) || isNil(alerts)) {
        setAssetsPoints({});
        return;
      }
      const points = {};
      for (const asset of assetsData.data) {
        const assetExists = alerts.some((alert) => alert.asset === asset.name && !alert.isEquipment);
        if (assetExists) {
          try {
            const response = await getPointsByAssets(asset.name);
            response.data.data.forEach((point, index) => {
              points[`${asset.name}${index + 1}`] = point;
            });
          } catch (error) {
            console.error(`Error fetching points for ${asset.name}:`, error);
            points[asset.name] = null;
          }
        }
      }
      setAssetsPoints(points);
    }
 
    fetchAssetsPoints();
  }, [assetsData, alerts]);
 
  const measureAlerts = useMemo(() => {
    return alerts ? alerts.filter((alert) => !alert.isEquipment) : [];
  }, [alerts]);
 
  const equipmentAlerts = useMemo(() => {
    return alerts ? alerts.filter((alert) => alert.isEquipment) : [];
  }, [alerts]);
 
  useEffect(() => {
    if (menuAccessVerification("/alarms", menuData, navigate)) {
      const menuOption = menuData.find((m) => m.page === "/alarms").value;
      dispatch(setSelectedMenuOptionAction(menuOption));
      loadAlerts({ assetName, setAlerts, setLoading, dispatch, navigate, intl });
    }
  }, [menuData, assetName, dispatch, intl, navigate]);
 
  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
        <CircularProgress />
      </div>
    );
  }
 
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div style={{ fontSize: "x-large", padding: "1rem", width: "100%" }}>{intl.formatMessage({ id: "alarms" })}</div>
      {/* Wrap Tabs and TabPanels inside TabContext */}
      <TabContext value={value}>
        <Box sx={{ width: "100%" }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab value="1" label={intl.formatMessage({ id: "measure.alerts" })} />
            <Tab value="2" label={intl.formatMessage({ id: "equipment.alerts" })} />
          </Tabs>
        </Box>
        <TabPanel sx={{ flex: 1, overflow: "auto", width: "100%", height: "100%" }} value="1">
          <AlarmTable
            alerts={measureAlerts}
            assetName={assetName}
            assetsData={assetsData}
            assetsPoints={assetsPoints}
            dispatch={dispatch}
            intl={intl}
            loading={loading}
            localeData={localeData}
            menuData={menuData}
            navigate={navigate}
            pointsData={pointsData}
            setAlerts={setAlerts}
            setLoading={setLoading}
            userData={userData}
            isEquipment={false}
          />
        </TabPanel>
        <TabPanel sx={{ flex: 1, overflow: "auto", width: "100%", height: "100%" }} value="2">
          <AlarmTable
            alerts={equipmentAlerts}
            assetName={assetName}
            assetsData={assetsData}
            assetsPoints={assetsPoints}
            dispatch={dispatch}
            intl={intl}
            loading={loading}
            localeData={localeData}
            menuData={menuData}
            navigate={navigate}
            pointsData={pointsData}
            setAlerts={setAlerts}
            setLoading={setLoading}
            userData={userData}
            isEquipment={true}
          />
        </TabPanel>
      </TabContext>
    </div>
  );
};
 
export default Alarms;
 